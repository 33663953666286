.typography-h1 {
  font-family: "Inter", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.typography-h2 {
  font-size: 42px !important;
  font-weight: 600 !important;
  letter-spacing: -2.5px !important;
  line-height: 40px !important;
  text-align: center !important;
  color: #ffffff !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: flex-start; /* Changed from center to flex-start */
}

.content {
  flex-grow: 1;
  width: 100%; /* Ensure it takes the full width */
}

.landing-container {
  display: flex;
  flex-direction: column;
  padding: 64px; /* Simplified padding */
  gap: 12px;
  align-items: center; /* Keep if you want to center content in this container */
  justify-content: center; /* Start content from the top */
  height: 100%;
}

.StyledButtonBase {
  background-color: rgba(76, 130, 251, 0.24) !important;

  &:hover .text-color-v6 {
    color: rgba(173, 188, 255, 0.24) !important;
  }
}

.text-color-v6 {
  color: #4c82fb !important;
  transition: color 125ms ease-in 0s;
}
