@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

html,
body,
#root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%; /* This can remain to ensure basic full height */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  min-height: 100vh; /* Ensure the gradient covers at least the viewport height */
  width: 100%;
  background-image: linear-gradient(rgba(22, 25, 43) 0%, rgb(7, 8, 22) 100%);
}

body {
  min-height: 100vh; /* Aligns with html to ensure coverage */
  position: relative;
  font-family: "Inter", sans-serif !important;
}

.app {
  width: 100%;
  min-height: 100vh; /* Ensure app container also covers the viewport height */
  position: relative;
  font-family: "Inter", sans-serif;
}

a {
  text-decoration: none;
}

/* Adjust your React component CSS as needed, keeping the above changes in mind */
